@import 'bootstrap/button-groups.scss';

.btn-group .btn-default.active {
  background-color: $brand-primary;
  color: #fff;
  box-shadow: none;

  &:hover,
  &:focus,
  &.focus {
    background-color: darken($brand-primary, 10%);
  }
}

// btn focus swtiching from browser default which is hidden to outline around the btn
.btn{
  &:focus,
  &.focus{
    box-shadow: 0 0 2px 3px #fff, 0 0 2px 4px #2B6BDD;
  }
}
