@import "bootstrap/forms.scss";

.form-control {
  appearance: none;
  box-shadow: none;
  padding: 5px $padding-base-vertical 4px;

  &[disabled],
  &[readonly],
  fieldset[disabled] & {
    color: $input-text-disabled;
    border: 1px solid $gray500;
    cursor: not-allowed;
  }
  &[readonly]{
    color: $input-text-readonly;
  }
}

.control-label,
label {
  color: $gray-dark;
  font-weight: 400;
  margin: 1px 0 3px;
  line-height: 20px;
}

.help-block {
  margin-top: $margin-small-vertical;
  margin-bottom: 0;
  line-height: 16px;
}

.has-error .control-label,
.has-warning .control-label {
  color: $gray-dark;
}

.has-warning .form-control {
  border: 1px solid $input-border;

  &:focus {
    border-color: $blue500;
  }
}

.has-error .form-control {
  border-width: 2px;
}

.has-error .help-block,
.has-warning .help-block {
  font-size: $font-size-small;
  color: $text-color;
}

.help-block__wrapper {
  display: flex;

  svg {
    margin-left: -4px;
    margin-right: $spacing-xxs;
  }

  .icon-error {
    color: $red500;
  }

  .icon-warning {
    color: $orange-ext2;

    .IconWarning__exclamation {
      fill: $gray-base !important;
    }
  }
}

// Selects
.cx-select {
  position: relative;

  & select {
    padding-right: 32px;
    appearance: none;
  }

  & .select-wrapper {
    position: relative;

    & .form-control[readonly] {
      pointer-events: none;
    }
  }

  & svg.icon-keyboard-arrow-down {
    position: absolute;
    top: $spacing-xs;
    right: $spacing-xs;
    pointer-events: none;
    fill: currentColor;
    z-index: 5;

    &.disabled,
    &.readonly {
      color: $gray;
    }
  }
}

.input-group-btn {
  button {
    padding-right: $spacing-s;
    padding-left: $spacing-s;
    margin-right: 0 !important;
  }

  ~ input {
    border-left: 0;
  }
}

.input-group-btn .dropdown-toggle {
  border-color: $input-border;

  &.btn.btn-default .caret {
    border-color: $text-color;
  }

  &:hover,
  &:active {
    border-color: $input-border;
    opacity: $opacity-hover;
  }
}

// Removes the caret on IE11
select::-ms-expand {
  display: none;
}
