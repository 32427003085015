@import 'bootstrap/panels.scss';

.panel {
  box-sizing: border-box;
  box-shadow: $box-shadow-shallow;
  padding: $panel-body-padding;

  > .panel-heading {
    border-bottom: none;
    padding: 0 0 $spacing-xs;
    font-weight: $headings-font-weight;
  }

  & .panel-body {
    padding: 0;
  }
}

.panel-primary,
.panel-success,
.panel-warning,
.panel-info,
.panel-danger {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 6px;
    background-color: $brand-primary;
  }
}

.panel-warning::before {
  background-color: $brand-warning;
}

.panel-success::before {
  background-color: $brand-success;
}

.panel-info::before {
  background-color: $brand-info;
}

.panel-danger::before {
  background-color: $brand-danger;
}
