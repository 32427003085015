$bootstrap-sass-asset-helper: false !default;
//
// Variables
// --------------------------------------------------

//== Prefix for CSS Classes
$prefix: 'cx-';

//== Colors
//
//## Branding colors
$cox-dark-blue: #003469;
$cox-blue: #0585c8;

// Base colors
$blue: #2b6bdd;
$dark-blue: #293447;
$green: #38bf2c;
$yellow: #fd0;
$orange: #ff6a1d;
$red: #dd2b2b;
$purple: #7f51db;
$white: #fff;

//## Gray and brand colors for use across Bootstrap.

//** Base grays;
$grey870: #16171a;
$grey670: #6d727a;
$grey530: #b2b6bf;
$grey400: #cacfd9;
$grey200: #e4e9ef;
$grey120: #f5f6f7;
$white800: #fafafa;

$grey870-opaque: #16171a;
$grey670-opaque: #6d727a;
$grey530-opaque: #b2b6bf;
$grey400-opaque: #cacfd9;
$grey200-opaque: #e4e9ef;
$grey120-opaque: #f5f6f7;
$white800-opaque: #fafafa;

$gray-base: #000 !default;
$gray-darker: $grey870 !default;
$gray-dark: $grey670 !default;
$gray: $grey530 !default;
$gray-light: $grey400 !default;
$gray-lighter: $grey200 !default;
$gray-lightest: $grey120 !default;

//## Alert colors
$brand-cox: $cox-dark-blue !default;
$brand-primary: $blue !default;
$brand-success: $green !default;
$brand-warning: $orange !default;
$brand-danger: $red !default;
$brand-info: lighten($brand-primary, 15%) !default;

//## Extended color palette
$gray800: #16171a;
$gray700: #6d727a;
$gray600: #b2b6bf;
$gray500: #cacfd9;
$gray400: #e4e9ef;
$gray300: #f5f6f7;
$gray200: #fafafa;

$blue800: #0d2245;
$blue700: #173a78;
$blue600: #2053ab;
$blue400: #639cff;
$blue500: $blue;
$blue300: #96bdff;
$blue200: #e3edff;
$blue-ext: #3bf;
$blue-ext2: #08effa;

$green800: #1a5915;
$green700: #298c20;
$green600: #31a626;
$green500: $green;
$green400: #62d957;
$green300: #83f279;
$green200: #c2ffbd;
$green-ext: #3fb;
$green-ext2: #7f3;

$yellow800: #e69100;
$yellow700: #fa0;
$yellow600: #fcc63f;
$yellow500: $yellow;
$yellow400: #fce135;
$yellow300: #fce765;
$yellow200: #fff5b3;
$yellow-ext: #ffec00;
$yellow-ext2: #f3ff00;

$orange800: #923a00;
$orange700: #cc4f10;
$orange600: #e65a15;
$orange500: $orange;
$orange400: #ff8649;
$orange300: #ffa87d;
$orange200: #ffd6c2;
$orange-ext: #f73;
$orange-ext2: #fb3;

$purple800: #462c78;
$purple700: #593999;
$purple600: #6c45ba;
$purple500: $purple;
$purple400: #9665f7;
$purple300: #b18aff;
$purple200: #d6c2ff;
$purple-ext: #73f;
$purple-ext2: #c800ff;

$red800: #781717;
$red700: #a61f1f;
$red600: #c42525;
$red500: $red;
$red400: #fa3c3c;
$red300: #ff7575;
$red200: #fcc;
$red-ext: #f37;
$red-ext2: #f3f;

//== Shadows
$box-shadow-shallow: 0 2px 4px rgba(0, 0, 0, 0.05);
$box-shadow-deep: 0 2px 16px rgba(0, 0, 0, 0.2);

//== Spacing
$spacing-default: 16px;
$spacing-none: 0;
$spacing-xxs: 2px;
$spacing-xs: 4px;
$spacing-s: 8px;
$spacing-ms: 12px;
$spacing-m: 16px;
$spacing-l: 24px;
$spacing-xl: 32px;
$spacing-xxl: 48px;

//== States Opacity
$opacity-hover: 0.7;
$opacity-active: 0.5;
$opacity-disabled: 0.5;

//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
$body-bg: $white800-opaque !default; // #fff !default;

//** Global text color on `<body>`.
$text-color: $grey870 !default; // $gray-dark !default;

//** Global textual link color.
$link-color: $brand-primary !default;
$link-hover-color: lighten($link-color, 12%) !default;
$link-hover-decoration: none !default;

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: "Roboto",
sans-serif !default; // "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-family-serif: Georgia,
"Times New Roman",
Times,
serif !default;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace: Menlo,
Monaco,
Consolas,
"Courier New",
monospace !default;
$font-family-base: $font-family-sans-serif !default;

$font-size-base: 14px !default;
$font-size-large: 16px !default;
$font-size-small: 12px !default;
$font-size-x-large: 24px !default;

$font-size-h1: 28px !default;
$font-size-h2: 24px !default;
$font-size-h3: 20px !default;
$font-size-h4: 16px !default;
$font-size-h5: 14px !default;
$font-size-h6: 12px !default;

//== Icons
$icon-size-sm: 16px;
$icon-size-m: 24px;
$icon-size-l: 32px;

//** Unit-less `line-height` for use in components like buttons.
$line-height-base: 1.4286 !default; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed: floor(($font-size-base * $line-height-base)) !default; // ~20px
$line-height-md: floor($line-height-computed * 1.2); // ~24px

//** By default, this inherits from the `<body>`.
$headings-font-family: inherit !default;
$headings-font-weight: 500 !default;
$headings-line-height: 1.1 !default;
$headings-color: inherit !default;

//== Components
//
//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).

$padding-base-vertical: $spacing-s !default;
$padding-base-horizontal: $spacing-m !default;

$padding-large-vertical: $spacing-m !default;
$padding-large-horizontal: $spacing-m !default;

$padding-small-vertical: $spacing-xs !default;
$padding-small-horizontal: $spacing-s !default;

$padding-xs-vertical: $spacing-xxs !default;
$padding-xs-horizontal: $spacing-xs !default;

$margin-base-vertical: 8px;
$margin-large-vertical: 16px;
$margin-small-vertical: 4px;

$line-height-large: 1.3333333 !default; // extra decimals for Win 8.1 Chrome
$line-height-small: 1.5 !default;

$border-radius-base: 4px;
$border-radius-small: $border-radius-base;
$border-radius-medium: floor($border-radius-base * 1.5);
$border-radius-large: floor($border-radius-base * 2);

$border-color-default: $gray500; // based on most borders in Bootstrap

//** Global color for active items (e.g., navs or dropdowns).
$component-active-color: #fff !default;
//** Global background color for active items (e.g., navs or dropdowns).
$component-active-bg: $brand-primary !default;

//** Width of the `border` for generating carets that indicate dropdowns.
$caret-width-base: 4px !default;
//** Carets increase slightly in size for larger components.
$caret-width-large: 5px !default;

//== Tables
//
//## Customizes the `.table` component with basic values, each used across all table variations.

//** Padding for `<th>`s and `<td>`s.
$table-cell-padding: 8px !default;
//** Padding for cells in `.table-condensed`.
$table-condensed-cell-padding: 5px !default;

//** Default background color used for all tables.
$table-bg: transparent !default;
//** Background color used for `.table-striped`.
$table-bg-accent: #f9f9f9 !default;
//** Background color used for `.table-hover`.
$table-bg-hover: #f5f5f5 !default;
$table-bg-active: $table-bg-hover !default;

//** Border color for table and cell borders.
$table-border-color: #ddd !default;

//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

$btn-font-weight: normal !default;

$btn-default-color: $brand-primary;
$btn-default-bg: #fff !default;
$btn-default-border: $brand-primary;

$btn-primary-color: #fff !default;
$btn-primary-bg: $brand-primary;
$btn-primary-border: transparent;

$btn-success-color: #fff !default;
$btn-success-bg: $brand-success !default;
$btn-success-border: $brand-success;

$btn-info-color: #fff !default;
$btn-info-bg: $brand-info !default;
$btn-info-border: $brand-info;

$btn-warning-color: #fff !default;
$btn-warning-bg: $brand-warning !default;
$btn-warning-border: $brand-warning;

$btn-danger-color: #fff !default;
$btn-danger-bg: $brand-danger !default;
$btn-danger-border: transparent;

$btn-link-disabled-color: $gray-light !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius-base: floor($border-radius-base * 1.5) !default;
$btn-border-radius-large: $border-radius-large !default;
$btn-border-radius-small: $border-radius-small !default;

//== Forms
//
//##


$focus-box-shadow: none;

//** `<input>` background color
$input-bg: #fff !default;
//** `<input disabled>` background color
$input-bg-disabled: $white800 !default;
$input-text-disabled: $gray !default;
$input-text-readonly: $grey670 !default;

//** Text color for `<input>`s
$input-color: $gray-darker !default; // $gray !default;
//** `<input>` border color
$input-border: $gray600 !default; // #ccc !default;

// TODO: Rename `$input-border-radius` to `$input-border-radius-base` in v4
//** Default `.form-control` border radius
// This has no effect on `<select>`s in some browsers, due to the limited stylability of `<select>`s in CSS.
$input-border-radius: $border-radius-base !default;
//** Large `.form-control` border radius
$input-border-radius-large: $border-radius-large !default;
//** Small `.form-control` border radius
$input-border-radius-small: $border-radius-small !default;

//** Border color for inputs on focus
// $input-border-focus:             #66afe9 !default;
$input-border-focus: $brand-primary;

//** Placeholder text color
$input-color-placeholder: $gray;

//** Default `.form-control` height
$input-height-base: 32px !default;
//** Large `.form-control` height
$input-height-large: (ceil($font-size-large * $line-height-large) + ($padding-large-vertical * 2) + 2) !default;
//** Small `.form-control` height
$input-height-small: (floor($font-size-small * $line-height-small) + ($padding-small-vertical * 2) + 2) !default;

$input-checkbox-size: 16px;
$input-radio-size: 16px;

//** `.form-group` margin
$form-group-margin-bottom: $spacing-ms !default;

$legend-color: $gray-dark !default;
$legend-border-color: #e5e5e5 !default;

//** Background color for textual input addons
$input-group-addon-bg: $gray300 !default;
//** Border color for textual input addons
$input-group-addon-border-color: $input-border !default;

//** Disabled cursor for form controls and buttons.
$cursor-disabled: not-allowed !default;

//== Dropdowns
//
//## Dropdown menu container and contents.

//** Background for the dropdown menu.
$dropdown-bg: #fff !default;
//** Dropdown menu `border-color`.
$dropdown-border: rgba(0, 0, 0, 0.15) !default;
//** Dropdown menu `border-color` **for IE8**.
$dropdown-fallback-border: #ccc !default;
//** Divider color for between dropdown items.
$dropdown-divider-bg: #e5e5e5 !default;

//** Dropdown link text color.
$dropdown-link-color: $gray-dark !default;
//** Hover color for dropdown links.
$dropdown-link-hover-color: darken($gray-dark, 5%) !default;
//** Hover background for dropdown links.
$dropdown-link-hover-bg: #f5f5f5 !default;

//** Active dropdown menu item text color.
$dropdown-link-active-color: $component-active-color !default;
//** Active dropdown menu item background color.
$dropdown-link-active-bg: $component-active-bg !default;

//** Disabled dropdown menu item background color.
$dropdown-link-disabled-color: $gray-light !default;

//** Text color for headers within dropdown menus.
$dropdown-header-color: $gray600 !default;

//** Deprecated `$dropdown-caret-color` as of v3.1.0
$dropdown-caret-color: #000 !default;

//-- Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
//
// Note: These variables are not generated into the Customizer.

$zindex-navbar: 1000 !default;
$zindex-dropdown: 1000 !default;
$zindex-popover: 1060 !default;
$zindex-tooltip: 1070 !default;
$zindex-navbar-fixed: 1030 !default;
$zindex-modal-background: 1040 !default;
$zindex-modal: 1050 !default;

//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs: 480px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min: $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone: $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm: 768px !default;
$screen-sm-min: $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet: $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md: 992px !default;
$screen-md-min: $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop: $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg: 1200px !default;
$screen-lg-min: $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop: $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1) !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;

//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
$grid-columns: 12 !default;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width: 30px !default;
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint: $screen-sm-min !default;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1) !default;

//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
$container-tablet: (720px + $grid-gutter-width) !default;
//** For `$screen-sm-min` and up.
$container-sm: $container-tablet !default;

// Medium screen / desktop
$container-desktop: (940px + $grid-gutter-width) !default;
//** For `$screen-md-min` and up.
$container-md: $container-desktop !default;

// Large screen / wide desktop
$container-large-desktop: (1140px + $grid-gutter-width) !default;
//** For `$screen-lg-min` and up.
$container-lg: $container-large-desktop !default;

//== Navbar
//
//##

// Basics of a navbar
$navbar-height: 50px !default;
$navbar-margin-bottom: $line-height-computed !default;
$navbar-border-radius: $border-radius-base !default;
$navbar-padding-horizontal: floor(($grid-gutter-width / 2)) !default;
$navbar-padding-vertical: (($navbar-height - $line-height-computed) / 2) !default;
$navbar-collapse-max-height: 340px !default;

$navbar-default-color: #777 !default;
// $navbar-default-bg:                #f8f8f8 !default;
$navbar-default-bg: #fff;
$navbar-default-border: darken($navbar-default-bg, 6.5%) !default;

// Navbar links
$navbar-default-link-color: #777 !default;
$navbar-default-link-hover-color: #333 !default;
$navbar-default-link-hover-bg: transparent !default;
$navbar-default-link-active-color: #555 !default;
$navbar-default-link-active-bg: darken($navbar-default-bg, 6.5%) !default;
$navbar-default-link-disabled-color: #ccc !default;
$navbar-default-link-disabled-bg: transparent !default;

// Navbar brand label
$navbar-default-brand-color: $navbar-default-link-color !default;
$navbar-default-brand-hover-color: darken($navbar-default-brand-color, 10%) !default;
$navbar-default-brand-hover-bg: transparent !default;

// Navbar toggle
$navbar-default-toggle-hover-bg: #ddd !default;
$navbar-default-toggle-icon-bar-bg: #888 !default;
$navbar-default-toggle-border-color: #ddd !default;

//=== Inverted navbar
// Reset inverted navbar basics
$navbar-inverse-color: lighten($gray-light, 15%) !default;
// $navbar-inverse-bg:                         #222 !default;
// $navbar-inverse-border:                     darken($navbar-inverse-bg, 10%) !default;
$navbar-inverse-bg: $brand-cox;
$navbar-inverse-border: $navbar-inverse-bg;

// Inverted navbar links
// $navbar-inverse-link-color:                 lighten($gray-light, 15%) !default;
$navbar-inverse-link-color: #fff;
$navbar-inverse-link-hover-color: #fff !default;
// $navbar-inverse-link-hover-bg:              transparent !default;
$navbar-inverse-link-hover-bg: darken($brand-cox, 5%);
$navbar-inverse-link-active-color: $navbar-inverse-link-hover-color !default;
// $navbar-inverse-link-active-bg:             darken($navbar-inverse-bg, 10%) !default;
$navbar-inverse-link-active-bg: $brand-primary;
$navbar-inverse-link-disabled-color: #444 !default;
$navbar-inverse-link-disabled-bg: transparent !default;

// Inverted navbar brand label
$navbar-inverse-brand-color: $navbar-inverse-link-color !default;
$navbar-inverse-brand-hover-color: #fff !default;
$navbar-inverse-brand-hover-bg: transparent !default;

// Inverted navbar toggle
$navbar-inverse-toggle-hover-bg: #333 !default;
$navbar-inverse-toggle-icon-bar-bg: #fff !default;
$navbar-inverse-toggle-border-color: #333 !default;

//== Navs
//
//##

//=== Shared nav styles
$nav-link-padding: 10px 15px !default;
$nav-link-hover-bg: $gray-lighter !default;

$nav-disabled-link-color: $gray-light !default;
$nav-disabled-link-hover-color: $gray-light !default;

//== Tabs
$nav-tabs-border-color: transparent !default; // #ddd !default;
$nav-tabs-background-color: #f1f2f4;
$nav-tabs-link-hover-border-color: transparent !default;

// $nav-tabs-active-link-hover-bg:             $body-bg !default;
$nav-tabs-active-link-hover-bg: transparent;
$nav-tabs-active-link-hover-color: $blue !default;
$nav-tabs-active-link-hover-border-color: $nav-tabs-border-color !default; // #ddd !default;

$nav-tabs-justified-link-border-color: $nav-tabs-border-color !default; // #ddd !default;
$nav-tabs-justified-active-link-border-color: $body-bg !default;

//== Pills
$nav-pills-border-radius: $border-radius-base !default;
$nav-pills-active-link-hover-bg: $component-active-bg !default;
$nav-pills-active-link-hover-color: $component-active-color !default;

//== Pagination
//
//##

$pagination-color: $link-color !default;
$pagination-bg: #fff !default;
$pagination-border: #ddd !default;

$pagination-hover-color: $link-hover-color !default;
$pagination-hover-bg: $gray-lighter !default;
$pagination-hover-border: #ddd !default;

$pagination-active-color: #fff !default;
$pagination-active-bg: $brand-primary !default;
$pagination-active-border: $brand-primary !default;

$pagination-disabled-color: $gray-light !default;
$pagination-disabled-bg: #fff !default;
$pagination-disabled-border: #ddd !default;

//== Pager
//
//##

$pager-bg: $pagination-bg !default;
$pager-border: $pagination-border !default;
$pager-border-radius: 15px !default;

$pager-hover-bg: $pagination-hover-bg !default;

$pager-active-bg: $pagination-active-bg !default;
$pager-active-color: $pagination-active-color !default;

$pager-disabled-color: $pagination-disabled-color !default;

//== Jumbotron
//
//##

$jumbotron-padding: 30px !default;
// $jumbotron-color:                inherit !default;
$jumbotron-color: #fff;
// $jumbotron-bg:                   $gray-lighter !default;
$jumbotron-bg: $brand-cox;
$jumbotron-heading-color: inherit !default;
$jumbotron-font-size: ceil(($font-size-base * 1.5)) !default;
$jumbotron-heading-font-size: ceil(($font-size-base * 4.5)) !default;

//== Form states and alerts
//
//## Define colors for form feedback states and, by default, alerts.

// $state-success-text:             #3c763d !default;
// $state-success-bg:               #dff0d8 !default;
// $state-success-border:           darken(adjust-hue($state-success-bg, -10), 5%) !default;
$state-success-text: $brand-success;
$state-success-bg: #dff0d8;
$state-success-border: $brand-success;

// $state-info-text:                #31708f !default;
// $state-info-bg:                  #d9edf7 !default;
// $state-info-border:              darken(adjust-hue($state-info-bg, -10), 7%) !default;
$state-info-text: $brand-primary;
$state-info-bg: #d9edf7;
$state-info-border: $brand-primary;

// $state-warning-text:             #8a6d3b !default;
// $state-warning-bg:               #fcf8e3 !default;
// $state-warning-border:           darken(adjust-hue($state-warning-bg, -10), 5%) !default;
$state-warning-text: $brand-warning;
$state-warning-bg: #fcf8e3;
$state-warning-border: $brand-warning;

// $state-danger-text:              #a94442 !default;
// $state-danger-bg:                #f2dede !default;
// $state-danger-border:            darken(adjust-hue($state-danger-bg, -10), 5%) !default;
$state-danger-text: $brand-danger;
$state-danger-bg: #f2dede;
$state-danger-border: $brand-danger;

//== Tooltips
//
//##

//** Tooltip max width
$tooltip-max-width: 200px !default;
//** Tooltip text color
$tooltip-color: $white !default;
//** Tooltip background color
$tooltip-bg: $blue800;
$tooltip-opacity: 1 !default;

//** Tooltip arrow width
$tooltip-arrow-width: 4px !default;
//** Tooltip arrow color
$tooltip-arrow-color: $tooltip-bg !default;

//== Popovers
//
//##

//** Popover body background color
$popover-bg: #fff !default;
//** Popover maximum width
$popover-max-width: 276px !default;
//** Popover border color
$popover-border-color: $border-color-default !default;
//** Popover fallback border color
$popover-fallback-border-color: #ccc !default;

//** Popover title background color
$popover-title-bg: darken($popover-bg, 3%) !default;

//** Popover arrow width
$popover-arrow-width: 10px !default;
//** Popover arrow color
$popover-arrow-color: $popover-bg !default;

//** Popover outer arrow width
$popover-arrow-outer-width: ($popover-arrow-width + 1) !default;
//** Popover outer arrow color
$popover-arrow-outer-color: fade_in($popover-border-color, 0.05) !default;
//** Popover outer arrow fallback color
$popover-arrow-outer-fallback-color: darken($popover-fallback-border-color, 20%) !default;

//== Labels
//
//##

//** Default label background color
$label-default-bg: $gray-light !default;
//** Primary label background color
$label-primary-bg: $brand-primary !default;
//** Success label background color
$label-success-bg: $brand-success !default;
//** Info label background color
$label-info-bg: $brand-info !default;
//** Warning label background color
$label-warning-bg: $brand-warning !default;
//** Danger label background color
$label-danger-bg: $brand-danger !default;

//** Default label text color
$label-color: #fff !default;
//** Default text color of a linked label
$label-link-hover-color: #fff !default;

//== Modals
//
//##

//** Padding applied to the modal body
$modal-inner-padding: 15px !default;

//** Padding applied to the modal title
$modal-title-padding: 15px !default;
//** Modal title line-height
$modal-title-line-height: $line-height-base !default;

//** Background color of modal content area
$modal-content-bg: #fff !default;
//** Modal content border color
$modal-content-border-color: rgba(0, 0, 0, 0.2) !default;
//** Modal content border color **for IE8**
$modal-content-fallback-border-color: #999 !default;

//** Modal backdrop background color
$modal-backdrop-bg: #000 !default;
//** Modal backdrop opacity
$modal-backdrop-opacity: 0.5 !default;
//** Modal header border color
$modal-header-border-color: #e5e5e5 !default;
//** Modal footer border color
$modal-footer-border-color: $modal-header-border-color !default;

$modal-lg: 900px !default;
$modal-md: 600px !default;
$modal-sm: 300px !default;

//== Alerts
//
//## Define alert colors, border radius, and padding.

$alert-padding: 15px !default;
$alert-border-radius: $border-radius-base !default;
$alert-link-font-weight: bold !default;

// $alert-success-bg:            $state-success-bg !default;
$alert-success-bg: #fff;
$alert-success-text: $state-success-text !default;
$alert-success-border: $state-success-border !default;

// $alert-info-bg:               $state-info-bg !default;
$alert-info-bg: #fff;
$alert-info-text: $state-info-text !default;
$alert-info-border: $state-info-border !default;

// $alert-warning-bg:            $state-warning-bg !default;
$alert-warning-bg: #fff;
$alert-warning-text: $state-warning-text !default;
$alert-warning-border: $state-warning-border !default;

// $alert-danger-bg:             $state-danger-bg !default;
$alert-danger-bg: #fff;
$alert-danger-text: $state-danger-text !default;
$alert-danger-border: $state-danger-border !default;

//== Progress bars
//
//##

//** Background color of the whole progress component
$progress-bg: #f5f5f5 !default;
//** Progress bar text color
$progress-bar-color: #fff !default;
//** Variable for setting rounded corners on progress bar.
$progress-border-radius: $border-radius-base !default;

//** Default progress bar color
$progress-bar-bg: $brand-primary !default;
//** Success progress bar color
$progress-bar-success-bg: $brand-success !default;
//** Warning progress bar color
$progress-bar-warning-bg: $brand-warning !default;
//** Danger progress bar color
$progress-bar-danger-bg: $brand-danger !default;
//** Info progress bar color
$progress-bar-info-bg: $brand-info !default;

//== List group
//
//##

//** Background color on `.list-group-item`
$list-group-bg: #fff !default;
//** `.list-group-item` border color
$list-group-border: $gray !default; // #ddd !default;
//** List group border radius
$list-group-border-radius: $border-radius-base !default;

//** Background color of single list items on hover
$list-group-hover-bg: #f5f5f5 !default;
//** Text color of active list items
$list-group-active-color: $component-active-color !default;
//** Background color of active list items
$list-group-active-bg: $component-active-bg !default;
//** Border color of active list elements
$list-group-active-border: $list-group-active-bg !default;
//** Text color for content within active list items
$list-group-active-text-color: lighten($list-group-active-bg, 40%) !default;

//** Text color of disabled list items
$list-group-disabled-color: $gray-light !default;
//** Background color of disabled list items
$list-group-disabled-bg: $gray-lighter !default;
//** Text color for content within disabled list items
$list-group-disabled-text-color: $list-group-disabled-color !default;

$list-group-link-color: #555 !default;
$list-group-link-hover-color: $list-group-link-color !default;
$list-group-link-heading-color: #333 !default;

//== Panels
//
//##

$panel-bg: #fff !default;
$panel-body-padding: $spacing-m !default; // 15px !default;
$panel-heading-padding: $spacing-m $spacing-m $spacing-none !default; // 10px 15px !default;
$panel-footer-padding: $spacing-s $spacing-m !default; // $panel-heading-padding !default;
$panel-border-radius: 0 !default;

//** Border color for elements within panels
$panel-inner-border: transparent !default;
$panel-footer-bg: #fff !default; // #f5f5f5 !default;

// $panel-default-text:
$panel-default-text: $text-color !default; // $gray-dark !default;
$panel-default-border: $border-color-default !default;
$panel-default-heading-bg: #fff !default; // #f5f5f5 !default;

$panel-primary-text: $brand-primary !default;
$panel-primary-border: $border-color-default; // $brand-primary !default;
$panel-primary-heading-bg: #fff; // $brand-primary !default;

$panel-success-text: $state-success-text !default;
$panel-success-border: $border-color-default; // $state-success-border !default;
$panel-success-heading-bg: #fff; // $state-success-bg !default;

$panel-info-text: $state-info-text !default;
$panel-info-border: $border-color-default; // $state-info-border !default;
$panel-info-heading-bg: #fff; // $state-info-bg !default;

$panel-warning-text: $state-warning-text !default;
$panel-warning-border: $border-color-default; // $state-warning-border !default;
$panel-warning-heading-bg: #fff; // $state-warning-bg !default;

$panel-danger-text: $state-danger-text !default;
$panel-danger-border: $border-color-default; // $state-danger-border !default;
$panel-danger-heading-bg: #fff; // $state-danger-bg !default;

//== Thumbnails
//
//##

//** Padding around the thumbnail image
$thumbnail-padding: 4px !default;
//** Thumbnail background color
$thumbnail-bg: $body-bg !default;
//** Thumbnail border color
$thumbnail-border: #ddd !default;
//** Thumbnail border radius
$thumbnail-border-radius: $border-radius-base !default;

//** Custom text color for thumbnail captions
$thumbnail-caption-color: $text-color !default;
//** Padding around the thumbnail caption
$thumbnail-caption-padding: 9px !default;

//== Wells
//
//##

$well-bg: #f5f5f5 !default;
$well-border: darken($well-bg, 7%) !default;

//== Badges
//
//##

$badge-color: $gray700 !default;
$badge-link-hover-color: #fff !default;
$badge-bg: $gray400 !default;

$badge-active-color: $link-color !default;
$badge-active-bg: #fff !default;

$badge-font-weight: bold !default;
$badge-line-height: 1 !default;
$badge-border-radius: 10px !default;

//== Breadcrumbs
//
//##

$breadcrumb-padding-vertical: 8px !default;
$breadcrumb-padding-horizontal: 15px !default;
$breadcrumb-bg: #f5f5f5 !default;
$breadcrumb-color: #ccc !default;
$breadcrumb-active-color: $gray-light !default;
$breadcrumb-separator: "/" !default;

//== Carousel
//
//##

$carousel-text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6) !default;

$carousel-control-color: #fff !default;
$carousel-control-width: 15% !default;
$carousel-control-opacity: 0.5 !default;
$carousel-control-font-size: 20px !default;

$carousel-indicator-active-bg: #fff !default;
$carousel-indicator-border-color: #fff !default;

$carousel-caption-color: #fff !default;

//== Close
//
//##

$close-font-weight: bold !default;
$close-color: #000 !default;
$close-text-shadow: 0 1px 0 #fff !default;

//== Code
//
//##

$code-color: $text-color !default; // #c7254e !default;
$code-bg: $grey120-opaque !default; // #f9f2f4 !default;

$kbd-color: #fff !default;
$kbd-bg: #333 !default;

$pre-bg: $gray-dark !default; // #f5f5f5 !default;
$pre-color: $white800 !default; // $gray-dark !default;
$pre-border-color: $gray-lighter !default; // #ccc !default;
$pre-scrollable-max-height: 340px !default;

//== Type
//
//##

//** Horizontal offset for forms and lists.
$component-offset-horizontal: 180px !default;
//** Text muted color
$text-muted: $gray-light !default;
//** Abbreviations and acronyms border color
$abbr-border-color: $gray-light !default;
//** Headings small color
$headings-small-color: $gray-light !default;
//** Blockquote small color
$blockquote-small-color: $gray-light !default;
//** Blockquote font size
$blockquote-font-size: ($font-size-base * 1.25) !default;
//** Blockquote border color
$blockquote-border-color: $gray-lighter !default;
//** Page header border color
$page-header-border-color: $gray-lighter !default;
//** Width of horizontal description list titles
$dl-horizontal-offset: $component-offset-horizontal !default;
//** Point at which .dl-horizontal becomes horizontal
$dl-horizontal-breakpoint: $grid-float-breakpoint !default;
//** Horizontal line color.
$hr-border: $gray !default; // $gray-lighter !default;

//
// Tracksuit/CX Specific Variables
// --------------------------------------------------

// Typography
// ------------------------------------------------
$letter-spacing-base: 0;

$font-weight-base: 400;
$font-weight-medium: 500;

$font-size-display-1: 4.143rem; // ~58px
$font-size-display-2: 3.439rem; // ~48px
$font-size-display-3: 2.857rem; // ~40px
$font-size-display-4: 2.429rem; // ~34px

// Spacing
// ------------------------------------------------
$spacer: 16px;
$spacer-x: $spacer;
$spacer-y: $spacer;

// Lead
// ------------------------------------------------
$lead-font-size: floor($font-size-base * 1.1);
$lead-font-size-sm: floor($font-size-base * 1.5);
$lead-font-weight: $font-weight-base;
$lead-line-height: 1.4;
$lead-letter-spacing: 0;
