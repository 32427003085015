@import 'bootstrap/breadcrumbs.scss';

.breadcrumb {
  background-color: transparent;
  padding: $spacing-s $spacing-m $spacing-s 0;
  font-size: $font-size-base;
  margin-bottom: $spacing-m;

  .active {
    color: $grey670;
  }
}
