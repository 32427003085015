@import 'bootstrap/navs.scss';

.nav-tabs {
  margin-bottom: $spacing-l;

  > li.active > a,
  > li.active > a:hover,
  > li.active > a:focus {
    color: $text-color;
    border-bottom: 2px solid $blue;
  }

  > li > a,
  > li > a:focus {
    border-radius: 0;
    color: $grey670;
    background-color: transparent;
    outline: 0;
  }

  > li > a:hover {
    background-color: #f1f2f4;
  }
}
