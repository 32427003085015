@import 'bootstrap/modals.scss';

.modal {
  &-header,
  &-footer {
    border: 0;
  }

  &-header {
    padding: $spacing-m $spacing-m 0 $spacing-m;

    button.close {
      color: $grey530;
      opacity: 1;

      &:hover {
        opacity: $opacity-hover;
      }
    }
  }

  &-body {
    padding: $spacing-m;
  }

  &-footer {
    padding: 0 $spacing-m $spacing-m $spacing-m;
  }
}
