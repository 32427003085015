@import '../../theme/src/base/_cx-bootstrap-variables.scss';

/* Documentation site component styles */
html,
body {
  margin: 0;
  height: 100vh;
}







#root {
  overflow-x: hidden;
}




// overrides extra margin on the warning message on the top of the page
#CardDefault-Warning.panel{
  margin-bottom: 0;
  padding: 36px;
}

// page structure
main {
  grid-area: main;
  padding-bottom: $spacing-xxl;
}

aside {
  grid-area: aside;
  background-color: $dark-blue;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  padding-bottom: $spacing-xxl;
  width: 260px;

  .navigation__logo {
    position: fixed;
    top: 54px;
    left: $spacing-xl;
  }

  .navigation__nav {
    position: fixed;
    top: 164px;
    bottom: 0;
    left: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: $spacing-xxl;
    width: 260px;
  }

  ul {
    padding: 0;
  }

  li,
  li > a,
  li label,
  li > a:focus,
  li > a:active,
  li > a:visited {
    color: $grey400;
    cursor: pointer;
  }

  li > a,
  li label {
    display: block;
    padding: 6px $spacing-xl;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
      color: $white;
    }
  }

  li a.active {
    background-color: rgba(255, 255, 255, 0.1);
    color: $white;
    position: relative;

    &::before {
      content: "";
      width: 4px;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      background-color: $green-ext;
    }
  }

  .navigation__header {
    margin-bottom: 0;
  }

  // sub-menu items
  .navigation__submenu {
    position: relative;

    ul.navigation--expanded {
      display: block;
    }

    input[type="checkbox"] {
      position: absolute;
      clip: rect(0, 0, 0, 0);
    }

    svg.navigation__arrow-down,
    svg.navigation__arrow-right {
      display: inline-block;
      position: absolute;
      right: $spacing-l;
      top: $spacing-xs;
      pointer-events: none;
    }

    ul {
      display: none;
    }

    ul a {
      padding: $spacing-xs $spacing-xl $spacing-xs $spacing-xxl;
    }

    .navigation__submenu {
      label {
        padding: $spacing-xs $spacing-xl $spacing-xs $spacing-xxl;
      }

      ul a {
        padding: $spacing-xs $spacing-xl $spacing-xs 64px;
      }
    }
  }

  .navigation__filter {
    margin: $spacing-s $spacing-xl $spacing-s $spacing-xl;

    input {
      background-color: rgba(255, 255, 255, 0.1);
      border: none;
      color: $white;
    }
  }
}

.navigation__button {
  display: none;
}

%center-docs {
  width: 850px;
  margin: 0 auto;
}

#SubmitButtonSizes,
#SubmitButtonStyles,
#DropdownMenuButtonSizes,
#DropdownMenuButtonStyles,
#ButtonSizes,
#ButtonStyles {
  margin-bottom: -8px;

  & .btn {
    margin: 0 8px 8px 0;
  }
}

// page styles
.componentPage__header {
  background-color: #f1f2f4;
  padding: $spacing-xxl $spacing-l $spacing-l;
  margin-bottom: $spacing-xl;

  h1 {
    font-weight: 300;
    font-size: 48px;
    line-height: 1.4;
    color: $grey670;

    @extend %center-docs;
  }
}

.componentPage__main {
  display: flex;
  background: $body-bg;

  @extend %center-docs;
}

.componentPage__content h2.componentPage__tagline {
  font-size: 20px;
  font-weight: 300;
  margin-bottom: $spacing-s;
  color: $grey670;

  ul {
    font-size: $font-size-base;
    font-weight: $font-weight-base;
  }
}

.componentPage__content {
  flex: 2;
  max-width: 676px;

  img {
    width: 100%;
  }

  h2,
  h3,
  h4 {
    margin: $spacing-xl 0 $spacing-l;
  }

  h2:first-child {
    margin-top: 0;
  }

  pre,
  code {
    white-space: pre-wrap;
  }

  ul,
  ol {
    margin-bottom: $spacing-m;
  }

  pre {
    background-color: #f1f2f4;
    border: 1px solid $border-color-default;
    color: $gray700;
    margin: $spacing-s 0 $spacing-m 0;

    code {
      background: none;
      text-shadow: none;
    }
  }

  code {
    background-color: #f1f2f4;
    color: $text-color;
  }

  .docs-buttons-wrap {
    margin-top: -$spacing-xs;

    button {
      margin-top: $spacing-xs;
    }
  }

  .resources {
    margin-bottom: 0;
    list-style-type: none;
    white-space: nowrap;
    padding-left: 0;

    li {
      margin-bottom: $spacing-xs;
    }
  }

  #resourcesTable td:first-child {
    white-space: nowrap;
  }
}

.componentPage__top-spacer + .componentPage__top-spacer {
  padding-top: 80px;
}

.componentPage__image {
  border: solid 1px lightGray;
  max-width: 480px;
}

.componentPage--1col {
  flex: 1;
  max-width: 100%;
}

.componentPage__nav {
  flex: 1;
  max-width: 150px;
  margin-left: $spacing-l;
  padding-top: $spacing-l;
  border-left: 1px solid $border-color-default;
  transition: padding 500ms ease;

  ul {
    list-style-type: none;
    padding-left: $spacing-l;

    ul {
      padding-left: $spacing-s;
      margin: $spacing-s 0 $spacing-m;
    }

    ul li {
      font-size: $font-size-small;
      line-height: 1.4;
    }
  }

  li {
    margin-bottom: $spacing-s;
  }
}

body footer {
  grid-area: footer;
  background-color: #f1f2f4;
  display: flex;
  justify-content: space-between;
  padding: $spacing-xxl $spacing-xxl;

  .footer__version,
  .footer__logo {
    align-self: center;
  }

  .footer__version p {
    margin-bottom: 0;
    //color: $grey670;
  }

  .footer__logo img {
    width: 250px;
  }
}

.example {
  border: solid 1px $border-color-default;
  background-color: $white;

  &.well {
    margin-bottom: 0;
    background-color: $white;
    padding: 0 $spacing-m $spacing-m;
    box-shadow: $box-shadow-shallow;
    border-radius: 0;
  }

  .example__interactions {
    margin: 0;
    list-style: none;
    padding: 0;

    button {
      border: none;
      padding: 0;
      margin: 0 $spacing-m 0 0;
      font-size: $font-size-small;
      text-transform: uppercase;
    }
  }

  pre {
    margin: 0 0 $spacing-m;
    background-color: #272822;

    code {
      color: $white;
    }
  }

  + .example {
    margin-top: $spacing-m;
  }

  &__heading {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $border-color-default;
    margin-bottom: $spacing-m;

    h4 {
      margin: $spacing-m 0;
      align-self: center;
    }

    button {
      padding: 0;
      color: $grey670;

      &:hover {
        color: $grey670;
        opacity: $opacity-hover;
      }
    }

    svg {
      display: block !important;
    }

    & .anchor-link {
      padding-left: 8px;
      display: none;
      align-self: center;
    }

    &:hover {
      & .anchor-link {
        display: inline;
      }
    }
  }

  &__code-button {
    align-self: center;
  }
}

.codeExample {
  position: relative;

  .button-group {
    position: absolute;
    top: 1px;
    right: 1px;
    margin: 0;
    display: flex;
  }

  .codeExample--copy::before {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 16 16' xml:space='preserve' width='16' height='16'%3E%3Cg fill='%23ffffff'%3E%3Crect x='2.5' y='3.5' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' width='10' height='12'/%3E%3Cpolyline fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' points='4.5,0.5 15.5,0.5 15.5,13.5 ' /%3E%3Cline fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' x1='5.5' y1='6.5' x2='9.5' y2='6.5'/%3E%3Cline fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' x1='5.5' y1='9.5' x2='9.5' y2='9.5'/%3E%3Cline fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' x1='5.5' y1='12.5' x2='9.5' y2='12.5'/%3E%3C/g%3E%3C/svg%3E");
  }

  .codeExample--copy.copied::before {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 16 16' xml:space='preserve' width='16' height='16'%3E%3Ctitle%3Echeck 2%3C/title%3E%3Cg class='nc-icon-wrapper' fill='%23ffffff'%3E%3Cpath fill='%23ffffff' d='M14.3,2.3L5,11.6L1.7,8.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l4,4C4.5,13.9,4.7,14,5,14s0.5-0.1,0.7-0.3 l10-10c0.4-0.4,0.4-1,0-1.4S14.7,1.9,14.3,2.3z'/%3E%3C/g%3E%3C/svg%3E");
  }

  .codeExample--github::before {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16'%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='M8 .2c-4.4 0-8 3.6-8 8 0 3.5 2.3 6.5 5.5 7.6.4.1.5-.2.5-.4V14c-2.2.5-2.7-1-2.7-1-.4-.9-.9-1.2-.9-1.2-.7-.5.1-.5.1-.5.8.1 1.2.8 1.2.8.7 1.3 1.9.9 2.3.7.1-.5.3-.9.5-1.1-1.8-.2-3.6-.9-3.6-4 0-.9.3-1.6.8-2.1-.1-.2-.4-1 .1-2.1 0 0 .7-.2 2.2.8.6-.2 1.3-.3 2-.3s1.4.1 2 .3c1.5-1 2.2-.8 2.2-.8.4 1.1.2 1.9.1 2.1.5.6.8 1.3.8 2.1 0 3.1-1.9 3.7-3.7 3.9.3.4.6.9.6 1.6v2.2c0 .2.1.5.6.4 3.2-1.1 5.5-4.1 5.5-7.6-.1-4.4-3.7-8-8.1-8z' clip-rule='evenodd'/%3E%3C/svg%3E");
  }

  button,
  a {
    position: relative;
    font-size: $font-size-small;
    border-radius: 0 2px 0 2px;
    padding: $spacing-xs $spacing-m $spacing-xs $spacing-xl;
    border: none;
    background-color: rgba(255, 255, 255, 0.1);
    color: $white;
    margin-right: 1px;
    display: block;

    &:focus {
      background-color: rgba(255, 255, 255, 0.1);
      color: $white;
    }

    &:hover,
    &:active:focus,
    &:active {
      background-color: rgba(255, 255, 255, 0.3);
      color: $white;
    }

    &::before {
      content: "";
      width: 16px;
      height: 16px;
      display: block;
      position: absolute;
      top: 5px;
      left: 8px;
    }
  }
}

.anchor {
  & .anchor-link {
    padding-left: 8px;
    display: none;
  }

  & h3:hover,
  & h4:hover {
    & .anchor-link {
      display: inline;
    }
  }
}

.anchor::before {
  display: block;
  height: 16px;
  margin-top: -16px;
  visibility: hidden;
  content: "";
}

.props {
  width: 100%;
}

code {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.iconblock {
  display: flex;
  flex-wrap: wrap;

  &__item {
    min-width: 200px;
    margin-bottom: $spacing-s;
  }
}

.props__name {
  font-weight: 500;
}

.navigation__filter {
  margin-top: 24px;
}

.show-grid {
  margin-bottom: 15px;
}

.show-grid [class^="col-"] {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #eee;
  background-color: rgba(86, 61, 124, 0.15);
  border: 1px solid #ddd;
  border: 1px solid rgba(86, 61, 124, 0.2);
}

.docs__header {
  width: 100%;
  border-radius: 0;
  position: fixed;
  z-index: 1000;
}

.docs__navigation {
  padding-bottom: 1em;
  position: fixed;
  top: 50px;
  bottom: 0;
  left: 0;
  z-index: 10;
  overflow: auto;
}

.docs__content {
  padding-top: 50px;
}

.toggle-example {
  &__button {
    margin: 0 8px 8px 0;
  }

  &__checkbox {
    margin: 0;
    display: inline-block;
    width: 200px;
  }

  &__row {
    margin-bottom: 16px;

    & .radio-inline {
      width: 200px;

      & + .radio-inline {
        margin: 0;
      }
    }
  }

  &__slider {
    margin-bottom: 16px;
  }
}

.table-toggle-button,
.copy-code-button {
  margin: 0 8px 8px 0;
}

/*
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/
@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Force table to not be like tables anymore */
  table.props {
    &,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    tr {
      border: 1px solid #ccc;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    td {
      /* Behave  like a "row" */
      border: none;
      border-bottom: 1px solid #eee;
      position: relative;
      padding-left: 32% !important;
      min-height: 36px;
    }

    td:nth-of-type(1) {
      background-color: #e8e8e8;
    }

    td::before {
      /* Now like a table header */
      position: absolute;

      /* Top/left values mimic padding */
      top: 6px;
      left: 6px;
      width: 25%;
      padding-right: 10px;
      white-space: nowrap;
      color: rgba(0, 0, 0, 0.87);
      font-weight: 500;
      text-align: left;
      border-right: 1px solid #ddd;
    }

    /* Label the data */
    td:nth-of-type(1)::before {
      content: "Name";
    }

    td:nth-of-type(2)::before {
      content: "Type";
    }

    td:nth-of-type(3)::before {
      content: "Default";
    }

    td:nth-of-type(4)::before {
      content: "Required";
    }

    td:nth-of-type(5)::before {
      content: "Description";
    }
  }
}

/* Home page */
.componentPage--homepage {
  font-size: $font-size-large;

  .componentPage__header {
    padding: 96px $spacing-l $spacing-xxl;
    line-height: 1.25;
  }

  .componentPage__tagline {
    margin: $spacing-l auto 0;
    width: 850px;
    font-weight: 300;
    color: $gray700;
  }

  .componentPage__content {
    margin-bottom: $spacing-xl;
  }

  .componentPage__content h3 {
    margin: $spacing-m 0 $spacing-l;
  }

  .componentPage__3up {
    display: flex;

    div {
      margin-right: $spacing-l;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.logo-cluster {
  div {
    display: flex;
    justify-content: space-evenly;
    margin: 0 $spacing-xl;
    opacity: 0.4;
    flex-flow: row wrap;
  }

  img {
    width: auto;
    margin-bottom: $spacing-xl;

    &:first-child {
      margin-right: $spacing-m;
    }

    &:last-child {
      margin-left: $spacing-m;
    }
  }

  div:first-child {
    margin-top: ($spacing-xl * 2);
  }
}

/* Guidelines */
.guidelines {
  h3 {
    margin-bottom: $spacing-xl;
  }

  ul {
    padding-left: $spacing-m;
    list-style-position: outside;
    margin-bottom: $spacing-l;
  }

  li {
    margin-bottom: $spacing-s;
  }
}

/* Release Notes */
.release {
  margin-bottom: $spacing-xxl;

  h2 {
    margin-bottom: $spacing-s;
  }
}

/* Responsive styles */
@media (max-width: 1150px) {
  .componentPage__header h1,
  .componentPage__main {
    width: 100%;
  }

  .componentPage__main {
    padding-left: $spacing-l;
    padding-right: $spacing-l;
  }
}

@media (max-width: 1024px) {
  .docFrame {
    grid-template-columns: 100%;
    grid-template-areas:
      "main"
      "footer";
  }

  .navigation__button {
    position: absolute;
    display: block;
    top: $spacing-l;
    left: $spacing-l;

    a {
      display: inline-flex;
      align-items: center;
      color: $white;

      &:hover {
        opacity: $opacity-hover;
      }
    }

    &.active {
      left: 284px;
      z-index: 1;
      transition: none;
    }
  }

  .componentPage__header {
    background-color: $dark-blue;
    display: flex;
    justify-content: space-between;
    padding: $spacing-l;

    h1 {
      text-align: center;
      font-size: $font-size-large;
      font-weight: normal;
      color: $white;
    }

    a:last-child {
      visibility: hidden;
    }
  }

  .componentPage--homepage .componentPage__header {
    h1 {
      font-size: 36px;
      font-weight: 300;
      text-align: left;
      width: 100%;
    }

    .componentPage__tagline {
      display: none;
    }
  }

  body aside {
    display: none;
    transition: 0.25s;

    &.active {
      grid-area: unset;
      z-index: 3;
      display: block;

      .navigation {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        width: 260px;
        background-color: $dark-blue;
        box-shadow: inset -10px 0 5px -5px hsla(0, 0%, 0%, 0.25);
      }

      .navigation__nav {
        box-shadow: inset -10px 0 5px -5px hsla(0, 0%, 0%, 0.25);
      }

      & ~ main {
        transform: translateX(260px);
        transition: 0.25s;
      }
    }
  }

  footer {
    grid-column: 1;
    padding: $spacing-l;
  }
}

@media (max-width: 700px) {
  .componentPage__nav {
    display: none;
  }

  .componentPage__3up {
    flex-wrap: wrap;

    div {
      margin-bottom: $spacing-xl;
    }
  }

  .footer__logo {
    display: none;
  }
}

/* styles on typography screen */
.typeExamples {
  .display-1,
  .display-2,
  .display-3,
  .display-4 {
    display: block;
    margin-bottom: $spacing-m;
  }

  .display-4 {
    margin-bottom: $spacing-xl;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: $spacing-m;
    margin-bottom: $spacing-m;
  }
}

/* styles for content examples */

.contentExample {
  margin-top: $spacing-s;
  margin-bottom: 0;

  .contentExample__header {
    display: flex;
    align-items: center;
    margin-bottom: $spacing-s;

    strong {
      margin-top: 2px;
      margin-left: $spacing-xs;
    }
  }

  .icon-complete {
    color: $green600;

    & ~ strong {
      color: $green800;
    }
  }

  .icon-restrict {
    color: $red500;

    & ~ strong {
      color: $red500;
    }
  }
}

/* styles for layout pages */

/* layout grid list */
.layoutContent {
  padding: $spacing-m;

  h3 {
    margin: 0 0 $spacing-s 0;
  }

  &__desc {
    color: $gray700;
    margin-bottom: $spacing-s;
  }

  a {
    margin-bottom: $spacing-xs;
  }
}

/* layout detail */
.layout {
  header {
    padding: $spacing-m;
    border-bottom: 1px solid $border-color-default;
    background-color: $white;
    box-shadow: $box-shadow-shallow;
    display: flex;
    justify-content: space-between;
    margin-bottom: $spacing-m;

    h3 {
      margin: 0;
    }
  }

  .show-grid {
    [class^="col-"] {
      padding-top: 10px;
      padding-bottom: 10px;
      background-color: transparent;
      border: transparent;
    }

    code {
      background-color: $gray400;
      display: block;
      padding: $spacing-s;
    }
  }
}

/** articles **/
.row__cards,
.row__cards [class^="col-"] {
  display: flex;
  align-items: stretch;
}


/* for the Firebase shutdown page */
.newHomepage{
  width: 100%;
  text-align: center;
  padding: 25px;
  .cxLogo{
    width: 260px;
    margin: 150px auto 50px auto;
  }
  h1{
    font-family: $font-family-sans-serif;
    font-weight: 200;
    font-size: 34px;
    padding: 25px 0;
  }
  p{
    padding: 0 0 25px 0;
  }
}
