@import "bootstrap/type.scss";
@import url("https://fonts.googleapis.com/css?family=Roboto:400,300,100,500,700|Roboto+Condensed:300,400,700");

html {
  font-size: $font-size-base;
}

body {
  font-weight: $font-weight-base;
  letter-spacing: $letter-spacing-base;
}

.page-header {
  border-bottom: none;
}

.display-1 {
  font-size: $font-size-display-1;
  line-height: ($spacing-s * 9);
  font-weight: 300;
}

.display-2 {
  font-size: $font-size-display-2;
  line-height: ($spacing-s * 7);
  font-weight: 300;
}

.display-3 {
  font-size: $font-size-display-3;
  line-height: ($spacing-s * 6);
  font-weight: 300;
}

.display-4 {
  font-size: $font-size-display-4;
  line-height: ($spacing-s * 5);
  font-weight: 300;
}

h1 {
  line-height: $spacing-xl;
}

h2 {
  line-height: $spacing-xl;
}

h3 {
  line-height: $spacing-l;
}

h4 {
  line-height: $spacing-m;
}

h5 {
  line-height: $spacing-m;
}

h6 {
  line-height: $spacing-ms;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0 0 $spacing-s;
}

dt,
kbd kbd,
label,
b,
optgroup,
strong {
  font-weight: 500;
}

// colored text overrides
.text-primary,
.text-info {
  color: $brand-primary;
}

.text-success {
  color: $brand-success;
}

.text-warning {
  color: $brand-warning;
}

.text-danger {
  color: $brand-danger;
}

// lead overrides
.lead {
  font-size: $lead-font-size;
  font-weight: $lead-font-weight;
  line-height: $lead-line-height;
  letter-spacing: $lead-letter-spacing;

  @media (min-width: $screen-sm-min) {
    font-size: $lead-font-size-sm;
  }
}

// improve list readability
.list-spaced li {
  margin-bottom: 3px;
}

.list-bordered li {
  border-top: 1px solid $gray-light;
}

.list-bordered li:last-child {
  border-bottom: 1px solid $gray-light;
}

// serif family helper
.text-serif {
  font-family: $font-family-serif;
}

// responsive text helpers
.text-xs-left {
  text-align: left;
}

.text-xs-right {
  text-align: right;
}

.text-xs-center {
  text-align: center;
}

@media (min-width: $screen-sm-min) {
  .text-sm-left {
    text-align: left;
  }

  .text-sm-right {
    text-align: right;
  }

  .text-sm-center {
    text-align: center;
  }
}

@media (min-width: $screen-md-min) {
  .text-md-left {
    text-align: left;
  }

  .text-md-right {
    text-align: right;
  }

  .text-md-center {
    text-align: center;
  }
}

@media (min-width: $screen-lg-min) {
  .text-lg-left {
    text-align: left;
  }

  .text-lg-right {
    text-align: right;
  }

  .text-lg-center {
    text-align: center;
  }
}
