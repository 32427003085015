// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

@mixin button-variant($color, $background, $border) {
  color: $color;
  background-color: $background;
  border-color: $border;

  &:focus,
  &.focus {
    color: $color;
    background-color: $background;
    border-color: $border;
  }

  &:hover {
    color: lighten($color, 12%);
    background-color: lighten($background, 12%);
    border-color: lighten($border, 12%);
  }

  &.active,
  &:active,
  .open > &.dropdown-toggle {
    color: lighten($color, 24%);
    background-color: lighten($background, 24%);
    border-color: lighten($border, 24%);
    background-image: none;

    &:hover,
    &:focus,
    &.focus {
      color: lighten($color, 24%);
      background-color: lighten($background, 24%);
      border-color: lighten($border, 24%);
    }
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: $background;
      border-color: $border;
    }
  }

  .badge {
    color: $background;
    background-color: $color;
  }
}
