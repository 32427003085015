@import "bootstrap/buttons.scss";

// remove browser outline
.btn:focus,
.btn.focus,
.btn:active:focus,
.btn:active.focus,
.btn.active:focus,
.btn.active.focus {
  outline: 0;
}

// adjust padding
.btn {
  padding: ($spacing-xs + 1) $spacing-m;
  line-height: $line-height-computed;
}

.btn-xs,
.btn-sm {
  padding: ($spacing-xxs - 1) $spacing-m;
}

.btn-lg {
  padding: ($spacing-ms + 1) $spacing-l;
}

// alternate button styles - takes care of focused, disabled, active, etc.
.btn-default {
  @include button-variant(
    $btn-default-color,
    $btn-default-bg,
    $btn-default-border
  );
}

.btn-primary {
  @include button-variant(
    $btn-primary-color,
    $btn-primary-bg,
    $btn-primary-border
  );
}

// Success appears as green
.btn-success {
  @include button-variant(
    $btn-success-color,
    $btn-success-bg,
    $btn-success-border
  );
}
// Info appears as blue-green
.btn-info {
  @include button-variant($btn-info-color, $btn-info-bg, $btn-info-border);
}
// Warning appears as orange
.btn-warning {
  @include button-variant(
    $btn-warning-color,
    $btn-warning-bg,
    $btn-warning-border
  );
}
// Danger and error appear as red
.btn-danger {
  @include button-variant(
    $btn-danger-color,
    $btn-danger-bg,
    $btn-danger-border
  );
}

.btn:active,
.btn.active {
  box-shadow: none;
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  opacity: $opacity-disabled;
}

.btn.btn-link.disabled {
  color: $link-color;
}

.btn.btn--loader {
  .cx-loadingIndicator {
    margin: -2px $spacing-s 0 0;
    display: inline-block;
    vertical-align: middle;
  }
}

.btn.btn--icon {
  padding: $spacing-xs - 1;
  border-radius: 6px;

  svg {
    display: block;
  }
}

.dropdown-toggle.btn,
.dropdown-toggle.btn:focus {
  .caret {
    color: inherit;
    display: inline-block;
    border-width: 0 2px 2px 0;
    border-style: solid;
    width: 8px;
    height: 8px;
    margin: -6px 2px 0 2px;
    transform: rotate(45deg);
  }

  &.btn-default .caret,
  &.btn-link .caret {
    border-color: $blue500;
  }

  &.btn-primary .caret,
  &.btn-danger .caret {
    border-color: $white;
  }

  &.btn-lg .caret {
    border-width: 0 2px 2px 0;
  }
}
